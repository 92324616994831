<template>
  <div class="user-card">
    <div class="top-area">
      <div class="star-area">
        <template v-if="useNewIcons">
          <GreenStar v-for="(item, index) in props.data.score" :key="index" />
        </template>
        <template v-else>
          <CommonImage
            v-for="(item, index) in props.data.score"
            :key="index"
            class="star"
            :lazy="true"
            :src="getCloudAssets('/images/pages/newHome/home-community-user-star.png')"
            background="linear-gradient(108deg, #aaa 25%, #e1e1e1 37%, #aaa 63%)"
          />
        </template>
      </div>
      <div class="comment">
        {{ props.data.comment }}
      </div>
    </div>

    <div class="user-area">
      <CommonImage
        class="avatar"
        :lazy="true"
        :src="props.data.avatar"
        background="linear-gradient(108deg, #aaa 25%, #e1e1e1 37%, #aaa 63%)"
      />
      <div class="info">
        <div class="name">{{ props.data.name }}</div>
        <div class="tag">{{ props.data.tag }}</div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { UsersSayType } from "~/types/pages/newHome"
import GreenStar from "@/assets/icons/pages/landing/green-star.svg"

interface IProps {
  data: UsersSayType
  useNewIcons: boolean
}

const props = withDefaults(defineProps<IProps>(), {
  data: () => ({
    name: "",
    tag: "",
    avatar: "",
    score: 0,
    comment: ""
  }),
  useNewIcons: false
})

onMounted(() => {})
</script>

<style lang="scss" scoped src="./index.scss" />
